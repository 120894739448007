window.loginButtonDisabled = true;
const changeLoginButton = (state) => {
  console.log('Load Service Worker: changeLoginButton to', state)
  window.loginButtonDisabled = state
  window.dispatchEvent(new Event('changeLoginButton'))
}

const checkWorkerRegistered = async () => {
  return await navigator.serviceWorker.getRegistrations().then(registrations => { return !!registrations.length });
}

window.registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      console.log('Load Service Worker: Registering Auth Worker...')
      try {
        window.authWorker = await navigator.serviceWorker.register('/service-worker.js', {
          scope: '/',
        })
        if(window.activeLoginFlow === undefined) {
          console.log('Load Service Worker: Registered Auth Worker', window.authWorker)
          defineWindowAsyncLoginVariable()
        }
        console.log('Load Service Worker: DEFINED', window)
        // Register Event Listener for postMessages from Worker
        navigator.serviceWorker.ready.then(() => {
          navigator.serviceWorker.addEventListener('message', event => {
            // Logout (and in doing so terminate fe_user session) in case the Worker signals we do not have tokens
            console.log('Load Service Worker: Received postMessage from Worker: ', event)
            if (event.data.action === 'logout' && !window.activeLoginFlow) {
              console.log("Load Service Worker: Logout requested by Worker. Reloading page to trigger logout. (This will also clear the session cookie.")
              window.location = "/en/?logintype=logout"
            }
            changeLoginButton(false)
          })
        })

        if (window.authWorker.installing) {
          console.log('Load Service Worker: Auth worker installing.')
        } else if (window.authWorker.waiting) {
          console.log('Load Service Worker: Auth worker installed.')
        } else if (window.authWorker.active) {
          console.log('Load Service Worker: Auth worker active.')
          changeLoginButton(false)
        }
        // Check if there is a new version of the worker available, and if so, update it and make sure waiting worker takes over
        window.authWorker.addEventListener('updatefound', () => {
          // Disable Button
          changeLoginButton(true)
          // Only register statechange event listener if we trigger an update
          try {
            console.warn('Load Service Worker: Auth worker update found.')
            window.authWorker.update().then(() => {
              console.warn('Load Service Worker: Auth worker update installed.')
              if (window.authWorker.waiting) {
                console.warn('Load Service Worker: Auth worker update installed. Sending skipWaiting event.')
                window.authWorker.waiting.postMessage({'action': 'skipWaiting'});
              }
              changeLoginButton(false)
            })
          } catch (error) {
            console.error(`Load Service Worker: Update failed with ${error}.`)
          }
        })
        return window.authWorker
      } catch (error) {
        console.error(`Registration failed with ${error}.`)
        return
      }
    } else {
      // TODO: Tell the users how they can resolve this error, i.e. use a browser that supports workers
      return
    }
  }

export default () => {
  /*
   * !!! THIS IS A GENERATED FILE - TO CHANGE CODE (IF YOU'RE NOT ALREADY HERE) PLEASE UPDATE src/template/load-service-worker.hbs !!!
   */
  const usermanagementUrl = 'https://usermanagement.staging.focus-kalkhoff-dealer.com'
  console.log('Load Service Worker: Usermanagement URL', usermanagementUrl)

  // Register global method to launch SSO Flow
  window.startSSOLoginFlow = function (updatePassword = false, bypassKcLoginprompt = false) {
    // Determine Locale
    const pathLocale = window.location.pathname.substring(1,3)
    const locale = ['en','de','nl','fr','es','pl'].includes(pathLocale) ? pathLocale : 'en'
    // Start Login Flow onload
    window.activeLoginFlow = true

    // Build URL to UM
    const action = updatePassword ? 'startLoginUpdatePassword' : 'startLogin'
    const start_login_url = new URL(`${usermanagementUrl}/${action}`)
    start_login_url.searchParams.append("ui_locales", locale)
    const support_token = (new URLSearchParams(window.location.hash.substr(2))).get('support_token')
    if (support_token) {
      start_login_url.searchParams.append("support_token", support_token)
    }
    if(bypassKcLoginprompt) {
      start_login_url.searchParams.append("idp", 'okta')
    }

    // Prepare Login Window
    const popupWidth = 600;
    const popupHeight = 550;
    const browserWidth = document.documentElement.clientWidth;
    console.log("Load Service Worker: test", document.documentElement.clientWidth );
    const browserHeight = document.documentElement.clientHeight;
    const screenWidth = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    console.log("Load Service Worker: test",screenWidth)
    const screenHeight = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const popupLeft = screenWidth + (browserWidth - popupWidth) / 2;
    const popupTop = screenHeight + (browserHeight - popupHeight) / 2;
    const loginWindowOptions = 'width=' + popupWidth + ',height=' + popupHeight + ',menubar=no,toolbar=no,location=no,top=' + popupTop + ',left=' + popupLeft;
    //const placeHolderUrl = 'data:text/html;base64,PGRpdiBzdHlsZT0icG9zaXRpb246YWJzb2x1dGU7dG9wOjUwJTtsZWZ0OjUwJTt3aWR0aDoxMnJlbTtoZWlnaHQ6MTJyZW07bWFyZ2luLXRvcDotNnJlbTttYXJnaW4tbGVmdDotNnJlbTtiYWNrZ3JvdW5kOnJnYmEoMjU1LDI1NSwyNTUsMC45NSkgdXJsKCdodHRwczovL3d3dy5mb2N1cy1rYWxraG9mZi1kZWFsZXIuY29tL3R5cG8zY29uZi9leHQvZGVhbGVycG9ydGFsX3NpdGVwYWNrYWdlL1Jlc291cmNlcy9QdWJsaWMvd3AvaW1hZ2VzL0RQTG9nb0JpY3ljbGVfZnVsbC5zdmcnKTtib3JkZXItcmFkaXVzOjk5OXB4OyI+'
    window.authPopup = window.open('about:blank', 'loginWindow', loginWindowOptions); // Open empty Pop-up

    // Make sure there is an active Service Worker. If not, register it. Then finally open window
    checkWorkerRegistered().then((isRegistered) => {
      if(!isRegistered) {
        console.log('Load Service Worker: Auth worker not active. Calling registerServiceWorker().')
        window.registerServiceWorker().then(() => {
          // Navigate Popup to actual login URL, only delay by 4 seconds if support_access
          if(!!support_token) {
            setTimeout(() => {
              window.authPopup.location = start_login_url;
            }, 4000)
          } else {
            window.authPopup.location = start_login_url;
          }
        })
      } else {
        // Navigate Popup to actual login URL
        window.authPopup.location = start_login_url;
      }
    });
  }

  // Register Auth Service Worker
  window.registerServiceWorker()

  // Enable Login Button at last after 5 Seconds after page load (to prevent users from being stuck with no login option)
  setTimeout(() => {
    changeLoginButton(false)
  }, 5000)

  // Register Event Listener to Pass Received Tokens from UM to Worker
  //   As we use variables for the Origin Check we need to ignore this line within our code analysis pipeline
  // nosemgrep
  window.addEventListener(
    'message',
    async event => {
      //Only process postMessages from UM origin
      if (event.origin === usermanagementUrl) {
        // Close the login window in case window.close() within popup did not work
        if(window.authPopup) {
          console.error('Login Callback: Closing login window - should not be necessary.')
          window.authPopup.close()
        }
        // Pass access_token to our service worker
        navigator.serviceWorker.ready.then(async registration => {
          // First make sure we have an active worker
          if(!(await checkWorkerRegistered())) {
            console.log('Login Callback: No active worker. Calling registerServiceWorker().')
            await window.registerServiceWorker().then(() => {
              if (!window?.authWorker?.active) {
                console.error(
                  'Login Callback: Could not send postMessage to Worker (!window.authWorker.active): ',
                  event.data,
                )
                return
              }

              console.log('Login Callback: Sending postMessage to Worker: ', event.data)
              window.authWorker.active.postMessage(event.data)
            })
          } else {
            console.log('Login Callback: Active worker found.')
            console.log('Login Callback: Sending postMessage to Worker: ', event.data)
            if(!registration.active) {
              window.registerServiceWorker().then(() => {
                window.location.reload()
              })
            } else {
              registration.active.postMessage(event.data)
            }
          }
          // Reload to trigger navigation to Dashboard if everything went well
          console.log('Login Callback: Reloading page to trigger navigation to Dashboard.')
          setTimeout(() => {
            window.activeLoginFlow = false
            window.location.reload()
          }, 1000)
        })
      } else {
        // console.warn('Received the following postMessage but unsure how to deal with it:', event)
      }
    },
    false,
  )
}

// Define getter/setter for activeLoginFlow variable to
// create "reactiveness" of window value
export function defineWindowAsyncLoginVariable() {
  Object.defineProperties(window, {
    _activeLoginFlow: {
      value: false,
      writable: true,
    },
    activeLoginFlow: {
      get: function () {
        return this._activeLoginFlow
      },
      set: function (val) {
        this._activeLoginFlow = val
        onActiveLoginFlowChange(this._activeLoginFlow)
      },
    },
  })
}

// Add Logic to update Login view accordingly
export function onActiveLoginFlowChange(activeLoginFlow) {
  window.dispatchEvent(new CustomEvent('loginStart', {detail: activeLoginFlow}))
  // const overlay = document.getElementById('fe-async-login-overlay')
  // console.log('OVERLAY', overlay)
  // if (overlay) {
  //   console.log('add or remove', activeLoginFlow)
  //   activeLoginFlow
  //     ? overlay.classList.add('fe-async-login-overlay--active')
  //     : overlay.classList.remove('fe-async-login-overlay--active')
  // }
  console.log(`activeLoginFlow was updated: ${activeLoginFlow}`)
}

// We could actively signal the worker that a logout was requested. Instead we will watch out for logout requests and end the session directly from the worker.
//export function signalLogoutToWorker() {
//  navigator.serviceWorker.ready.then(registration => {
//    registration.active.postMessage({"logout":true})
//    window.location.reload()
//  })
//}